
'use strict';

/**
 * Revise Schema Values
 */
function init() {
    setTimeout(function () {
        if($('div[data-bv-show="inline_rating"] a').length > 0) {
            $('div[data-bv-show="inline_rating"] a').removeAttr('itemscope').removeAttr('itemtype').removeAttr('itemprop');
        }
    }, 1500);
}

module.exports = function () {
    init();
};
