'use strict';

window.$ = require('jquery');
window.jQuery = require('jquery');
window.dataLayer = window.dataLayer || [];
dataLayer.push({'event': 'jQueryLoaded'});
var processInclude = require('base/util');
$(document).ready(function () {
    let features = JSON.parse(document.querySelector('body').dataset.features);
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('core/components/header'));
    processInclude(require('./components/footer'));
    if (features && features.sfraEnableTransactions) {
        processInclude(require('./components/miniCart'));
    }
    processInclude(require('./components/floatLabels'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('store/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    if (features && features.enableCountrySelectorPageDesigner) {
        processInclude(require('base/components/countrySelector'));
    }
    processInclude(require('core/components/toolTip'));
    processInclude(require('core/accelerator/glide'));
    processInclude(require('./accelerator/slider'));
    // processInclude(require('./product/quickView'));
    // processInclude(require('core/components/instagram'));
    if (features && features.bvEnabled) {
        processInclude(require('./components/schema'));
    }
    processInclude(require('store/components/video'));
    if (features && features.enableGeoLocation) {
        processInclude(require('./components/geoLocation'));
    }
    processInclude(require('./components/banner'));
    if (features && features.enableChannelSite && features.csRefreshJsUrl) {
        processInclude(require('channelsight/csReInit'));
    }
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
// require('core/thirdParty/hoverIntent');
require('core/thirdParty/smartResize');
require('./thirdParty/matchHeight');
require('core/thirdParty/hoverIntent');
