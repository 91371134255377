'use strict';

var $scrollTop = $(window).scrollTop();
var $body = $('body');
var $header = $('.page > header');
var $features = document.querySelector('body').dataset.features ? document.querySelector('body').dataset.features : {};

var isSticky = $features && $features.enableStickyHeader;

/**
 * Set Scroll Fix
 * @param {number} scrollPos - The current scroll position.
 * @param {number} offset - The current offset.
 * @param {string} breakpoint - The breakpoint to test against.
 */
function setScrollFx(scrollPos, offset, breakpoint) {
    var scrollDir = scrollPos > $scrollTop ? 'down' : 'up';
    var hOffset = parseInt($header.css('top'), 10);
    // Toggle utility class on scroll
    if (scrollPos > 1) {
        $body.addClass('is-scrolled');
    } else {
        $body.removeClass('is-scrolled');
    }

    // Set scroll FX when applicable - condense header on scroll down only, toggle utility class
    if (breakpoint === 'mobile') {
        if (scrollDir === 'down') {
            // Scroll down FX
            $header.css('top', offset).addClass('offset');
        } else if (scrollDir === 'up') {
            // Scroll up FX
            $header.css('top', 0).removeClass('offset');
        }

        // Check for header offset, set body padding, suggestions position
        if ($header.hasClass('offset')) {
            $body.css('padding-top', ($header.height() + hOffset) + 10);
            $('.suggestions-inner-wrapper').height($(window).height() - $header.height()).parent().css('top', ($header.height() + hOffset));
            $('.refinement-bar').height($(window).height() - $header.height()).css('top', ($header.height() + hOffset));
        } else {
            $body.css('padding-top', $header.height());
            $('.suggestions-inner-wrapper').height($(window).height() - $header.height()).parent().css('top', $header.height());
            $('.refinement-bar').height($(window).height() - $header.height()).css('top', $header.height());
        }
    } else {
        // Reset offset on Desktop
        $header.attr('style', '');
        $body.attr('style', '');
        $('.refinement-bar').attr('style', '');
    }
    // Reset scroll position, prevent negative scroll
    $scrollTop = scrollPos <= 0 ? 0 : scrollPos;
}

/**
 * Sets header position
 */
function setHeader() {
    var $scrollPos = $(window).scrollTop();
    var $offsetH = -($header.outerHeight());
    var $bp = window.matchMedia('(max-width: 48em)').matches ? 'mobile' : 'desktop';
    // Set Header FX
    setScrollFx($scrollPos, $offsetH, $bp);
}

module.exports = function () {
    // Set Sticky if Sticky Feature is Enabled
    if (isSticky) {
        setHeader();
    }

    $('.header-slot').on('click', '.close-button', function (e) {
        e.preventDefault();

        if (isSticky) {
            setHeader();
        }
    });

    $('body').on('click', '.filter-results, .refinement-bar .close', function () {
        if (isSticky) {
            setHeader();
        }
        $('body').toggleClass('filter-active');
    });

    $(document).ready(function () {
        $('.panel-toggle').on('click', function (e) {
            e.preventDefault();
            $('.panel-toggle.active').toggleClass('active btn-primary btn-outline-primary');
            $(this).toggleClass('active btn-primary btn-outline-primary');
            $('.panel.is-active').removeClass('is-active');
            $('.panel').eq($(this).parent().index()).addClass('is-active');
        });
    });

    $(window).smartresize(function () {
        if (isSticky) {
            setHeader();
        }
    });

    $(window).on('scroll', function () {
        if (isSticky) {
            setHeader();
        }
    });
};
