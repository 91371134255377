'use strict';

require('core/thirdParty/slick');

module.exports = function () {
    $('.slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3.1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    });

    $('.product-slider').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 4.25,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    });
};
