'use strict';

var Glide = require('../thirdParty/glide');

module.exports = function () {
    initializePageDesignerDefaultCarousels();
    initializeRoutineCarousels();
    initializeSubCatCarousels();
};

/**
 * Initialize all routine carousels
 */
function initializeSubCatCarousels() {
    var allGlide = document.querySelectorAll('.sub-cat-article-slider.glide');
    allGlide.forEach((potentialGlideSlider) => {
        var glideOptions = JSON.parse(potentialGlideSlider.dataset.glide);
        // get current user's screen width
        var userScreenWidth = window.innerWidth;
        var minimumTilesPerBreakpointMap = getTilesPerBreakpoint(glideOptions);
        var currentGlideTrack = potentialGlideSlider.querySelector('.glide__track');

        if (currentGlideTrack && currentGlideTrack.querySelector('.inner-sub-cat-slider')) {
            var glideTileContainer = currentGlideTrack.querySelector('.inner-sub-cat-slider');
            var tileCount = glideTileContainer.children.length;
            var minimumAllowedSlides = getMinimumAllowedTilesAtBreakpoint(userScreenWidth, minimumTilesPerBreakpointMap);
            if (tileCount <= minimumAllowedSlides) {
                potentialGlideSlider.classList.add('glide-no-init');
            } else {
                var currentGlide = new Glide(potentialGlideSlider, glideOptions).mount();
                glideTileContainer = initializeCustomGlideLogic(potentialGlideSlider, tileCount, glideTileContainer, minimumAllowedSlides, currentGlide, '.inner-sub-cat-slider');
            }
        }
    });
}

/**
 * Initialize all routine carousels
 */
function initializeRoutineCarousels() {
    var allGlide = document.querySelectorAll('.product-routine .glide');
    allGlide.forEach((potentialGlideSlider) => {
        var glideOptions = JSON.parse(potentialGlideSlider.dataset.glide);
        // get current user's screen width
        var userScreenWidth = window.innerWidth;
        var minimumTilesPerBreakpointMap = getTilesPerBreakpoint(glideOptions);
        var currentGlideTrack = potentialGlideSlider.querySelector('.glide__track');

        if (currentGlideTrack && currentGlideTrack.querySelector('.routine-slider')) {
            var glideTileContainer = currentGlideTrack.querySelector('.routine-slider');
            var tileCount = glideTileContainer.children.length;
            var minimumAllowedSlides = getMinimumAllowedTilesAtBreakpoint(userScreenWidth, minimumTilesPerBreakpointMap);
            if (tileCount <= minimumAllowedSlides) {
                potentialGlideSlider.classList.add('glide-no-init');
            } else {
                var currentGlide = new Glide(potentialGlideSlider, glideOptions).mount();
                glideTileContainer = initializeCustomGlideLogic(potentialGlideSlider, tileCount, glideTileContainer, minimumAllowedSlides, currentGlide, '.routine-slider');
            }
        }
    });
}

/**
 * Initialize all page designer carousels
 */
function initializePageDesignerDefaultCarousels() {
    var allGlide = document.querySelectorAll('.pd-carousel-glide-init');
    allGlide.forEach((potentialGlideSlider) => {
        var glideOptions = JSON.parse(potentialGlideSlider.dataset.glide);
        // get current user's screen width
        var userScreenWidth = window.innerWidth;
        var minimumTilesPerBreakpointMap = getTilesPerBreakpoint(glideOptions);
        var currentGlideTrack = potentialGlideSlider.querySelector('.glide__track');

        if (currentGlideTrack && currentGlideTrack.querySelector('.pd-slider')) {
            var glideTileContainer = currentGlideTrack.querySelector('.pd-slider');
            var tileCount = glideTileContainer.children.length;
            var minimumAllowedSlides = getMinimumAllowedTilesAtBreakpoint(userScreenWidth, minimumTilesPerBreakpointMap);
            if (tileCount <= minimumAllowedSlides) {
                potentialGlideSlider.classList.add('glide-no-init');
            } else {
                var currentGlide = new Glide(potentialGlideSlider, glideOptions).mount();
                glideTileContainer = initializeCustomGlideLogic(potentialGlideSlider, tileCount, glideTileContainer, minimumAllowedSlides, currentGlide, '.pd-slider');
            }
        }
    });
}

/**
 * Disable arrows when we reach the bounds of the slider, add some meta data to the slider
 * @param {Element} potentialGlideSlider slider that we're initializing
 * @param {Number} tileCount number of tiles in the slider
 * @param {Element} glideTileContainer element that contains all the tiles
 * @param {Number} minimumAllowedSlides number of visible slides allowed at the current breakpoint
 * @param {Object} currentGlide Glide API object
 * @returns {Element} glideTileContainer element that contains all the tiles
 */
function initializeCustomGlideLogic(potentialGlideSlider, tileCount, glideTileContainer, minimumAllowedSlides, currentGlide, wrapperClass) {
    potentialGlideSlider.dataset.tileCount = tileCount;
    var glideTileContainer = potentialGlideSlider.querySelector(wrapperClass);
    for (var i = 0; i < glideTileContainer.children.length; i++) {
        glideTileContainer.children[i].dataset.glideSlideIndex = i;
    }
    potentialGlideSlider.dataset.visibleSlides = minimumAllowedSlides;
    // start with the previous arrow disabled
    currentGlide.selector.querySelector('.glide__arrows .glide__arrow--left').classList.add('disabled');
    // Handle disabling the arrows once we're at the end of the slider
    disableArrowAtCarouselBounds(currentGlide);
    return glideTileContainer;
}

/**
 * Disable arrows when we reach the bounds of the slider
 * @param {Object} currentGlide glide API object, see docs here: https://glidejs.com/docs/
 */
function disableArrowAtCarouselBounds(currentGlide) {
    currentGlide.on('run', function (arrow) {
        var activeSlideIndex = currentGlide.index;
        if ((currentGlide.selector.dataset.tileCount - activeSlideIndex - 1) < currentGlide.selector.dataset.visibleSlides) {
            currentGlide.selector.querySelector('.glide__arrows .glide__arrow--right').classList.add('disabled');
        } else {
            currentGlide.selector.querySelector('.glide__arrows .glide__arrow--right').classList.remove('disabled');
        }
        if (activeSlideIndex === 0) {
            currentGlide.selector.querySelector('.glide__arrows .glide__arrow--left').classList.add('disabled');
        } else {
            currentGlide.selector.querySelector('.glide__arrows .glide__arrow--left').classList.remove('disabled');
        }
    });
}

/**
 * return the value of the minimumSlidesPerBreakpointMap object that is less than or equal to the userScreenWidth
 * @param {Number} userScreenWidth width of the user's screen
 * @param {Object} minimumTilesPerBreakpointMap object that contains the minimum number of slides allowed per breakpoint
 * @returns 
 */
function getMinimumAllowedTilesAtBreakpoint(userScreenWidth, minimumTilesPerBreakpointMap) {
    var minimumAllowedTiles = 0;
    var breakpointOptions = Object.keys(minimumTilesPerBreakpointMap).map(Number);
    var currentUserBreakpoint = 0;
    for (var i = 0; i < breakpointOptions.length; i++) {
        if (i + 1 > breakpointOptions.length - 1) {
            currentUserBreakpoint = breakpointOptions[i];
            break;
        } else if (userScreenWidth >= breakpointOptions[i] && userScreenWidth < breakpointOptions[i + 1]) {
            currentUserBreakpoint = breakpointOptions[i];
            break;
        }
    }
    minimumAllowedTiles = minimumTilesPerBreakpointMap[currentUserBreakpoint];
    return minimumAllowedTiles;
}

/**
 * We need a map of the minimum number of slides allowed per breakpoint.
 * @param {Object} glideOptions options that the Glide constructor takes to initialize the carousel
 * @returns {Object} object that contains the minimum number of slides allowed per breakpoint
 */
function getTilesPerBreakpoint (glideOptions) {
    if (!glideOptions.hasOwnProperty('breakpoints') && glideOptions.hasOwnProperty('perView')) {
        return glideOptions.perView;
    } else if (!glideOptions.hasOwnProperty('breakpoints') && !glideOptions.hasOwnProperty('perView')) {
        // return default value, only render glide slider if there's more than 2 slides
        return {
            0: 2
        }
    }
    var breakpointOptions = glideOptions.breakpoints;
    var tilesPerBreakpointMap = {};
    var breakpointKeys = Object.keys(breakpointOptions);
    var breakpointValues = Object.values(breakpointOptions);
    // add '0' to the beginning of the breakpointKeys array
    breakpointKeys.unshift('0');

    for (var i = 0; i < breakpointKeys.length; i++) {
        if (i === breakpointValues.length) {
            tilesPerBreakpointMap[breakpointKeys[i]] = glideOptions.perView;
            break;
        }
        var breakpointValue = breakpointValues[i];
        tilesPerBreakpointMap[breakpointKeys[i]] = breakpointValue.perView;
    }
    return tilesPerBreakpointMap;
}