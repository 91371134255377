'use strict';

function init(csRefreshJsUrl) {
    $(document).ajaxSuccess(
        function () {
            // data-instanceid is applied to the button element when initialized, 
            // if it does not exist we have uninitialized buttons on the page
            if ($('.cswidget-js').not('[data-instanceid]')) {
                $('script[src="' + csRefreshJsUrl + '"]').remove();
                $('<script>').attr('src', csRefreshJsUrl).appendTo('head');
            }
        }
    );
}
module.exports = function () {
    let features = JSON.parse(document.querySelector('body').dataset.features);
    if (features && features.enableChannelSite && features.csRefreshJsUrl && features.enableProductTileBuyNow) {
        init(features.csRefreshJsUrl);
    }
};