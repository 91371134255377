'use strict';

function showGeoModal() {

    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="geolocationModal" aria-modal="true" role="dialog" style="display: block;">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer">'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);

    var formElements = $('#GeoLocationFormContainer').html();
    $('.modal-body').html(formElements);
    $('#geolocationModal').modal('show');
}

function init() {

    if($('.geolocationChecks').length > 0) {
        if (localStorage.getItem("selectedCountry") === null && $('#GeoLocationFormContainer').length > 0) {
            setTimeout(showGeoModal, 1000);
        }
    }

    $('#geolocation-button-text-js-target').text($('.geolocation-button-text').text());

    $('#geolocation-detected-country').text($('#geolocation-country-name').text())

    $('#geolocationModal .GeoLocationSelector').on('change',function() {
        var currURL = $(this).val();
    });

    $(document).on('click','#geolocationModal .modal-body .close-btn',function() {
        $('body').removeClass('modal-open');
        $('#geolocationModal').hide();
        $('.modal-backdrop').remove();
        localStorage.setItem("selectedCountry", true);
    })

    $(document).on('click','#geolocationModal .GeoLocationSelectorBTN',function() {
        var currURL = $('#geolocationModal .GeoLocationSelector').val();
        localStorage.setItem("selectedCountry", true);
        window.location = currURL;
    });
}

module.exports = function () {
    if (document.querySelector('body').dataset.features) {
        let features = JSON.parse(document.querySelector('body').dataset.features);
        if (features && features.enableGeoLocation) {
            init();
        }
    }
};
