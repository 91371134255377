'use strict';

module.exports = function () {
    // check for textbox elements
    if ($('.editorialRichText-component-container').length > 1) {
        $('.editorialRichText-component-container').each(function () {
            // check each textbox for h1s that have sibling h1s
            if ($(this).find('h1').siblings('h1').length > 0) {
                var h1Count = $($(this).find('h1')).length - 1;
                var h1Text = '';

                // now that we have a group of h1s, loop through each
                $($(this).find('h1')).each(function (i) {
                    // check to see if we are on at the last h1
                    if (i === h1Count) {
                        // if on the last one, add the text to our variable and then replace the html with our combined text
                        h1Text += $(this).text();
                        $(this).html(h1Text);
                    } else {
                        // if not the last, grab the text, add a <br/> tag and then delete the repeated h1 element
                        h1Text += $(this).text() + ' <br/>';
                        $(this).remove();
                    }
                });
            }
        });
    }
};
