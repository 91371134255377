'use strict';
// all footer sign up javascript pulled out of file for GALSUP-182

var scrollAnimate = require('base/components/scrollAnimate');

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });
};
