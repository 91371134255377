'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i += 1) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = function () {
    if ($('.valid-cookie-warning').length > 0) {
        var previousSessionID = window.localStorage.getItem('previousSid');
        var currentSessionID = getCookie('sid');
        if (!previousSessionID && currentSessionID) {
            // When a user first time visit the home page,
            // set the previousSessionID to currentSessionID
            // and Show the cookie alert
            previousSessionID = currentSessionID;
            window.localStorage.setItem('previousSid', previousSessionID);
            $('.cookie-warning-messaging').show();
        } else if (
            previousSessionID
            && previousSessionID === currentSessionID
        ) {
            // Hide the cookie alert if user is in the same session
            $('.cookie-warning-messaging').hide();
        } else {
            // Clear the previousSessionID from localStorage
            // when user session is changed or expired
            window.localStorage.removeItem('previousSid');
        }
    }

    $(document).on(
        'click',
        '#onetrust-accept-btn-handler, #accept-recommended-btn-handler, .save-preference-btn-handler',
        function () {
            localStorage.setItem('oneTrustConsented', true);
            if (
                'oneTrustConsented' in localStorage
                && localStorage.getItem('oneTrustConsented') != null
            ) {
                var oneTrustConsented = localStorage.getItem('oneTrustConsented');
                $(
                    '.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget'
                ).each(function () {
                    $(this).attr('data-cookie-consent', oneTrustConsented);
                });
            }
        }
    );

    $(document).on(
        'click',
        '.ot-btn-container .ot-pc-refuse-all-handler',
        function () {
            if (
                'oneTrustConsented' in localStorage
                && localStorage.getItem('oneTrustConsented') != null
            ) {
                localStorage.removeItem('oneTrustConsented');
            }
        }
    );

    if (
        'oneTrustConsented' in localStorage
        && localStorage.getItem('oneTrustConsented') != null
    ) {
        var oneTrustConsented = localStorage.getItem('oneTrustConsented');
        $(
            '.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget'
        ).each(function () {
            $(this).attr('data-cookie-consent', oneTrustConsented);
        });
    }
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        var sParameterName;
        var i;

        for (i = 0; i < sURLVariables.length; i += 1) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    // this handles the CS UTM parameters on the user's first page load, the rest of their journey
    // is handled by the dynamicTrackingScript.isml provided by ChannelSight
    var utmSource = getUrlParameter('utm_source');
    if (utmSource) {
        $('.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget').each(function () {
            $(this).attr('data-csi_utm_source', utmSource);
        });
    }

    var utmContent = getUrlParameter('utm_content');
    if (utmContent) {
        $('.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget').each(function () {
            $(this).attr('data-csi_utm_content', utmContent);
        });
    }

    var utmMedium = getUrlParameter('utm_medium');
    if (utmMedium) {
        $('.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget').each(function () {
            $(this).attr('data-csi_utm_medium', utmMedium);
        });
    }

    var utmCampaign = getUrlParameter('utm_campaign');
    if (utmCampaign) {
        $('.tile-cswidget.cswidget, .pdp-cswidget.cswidget, .cswidget.global-cswidget').each(function () {
            $(this).attr('data-csi_utm_campaign', utmCampaign);
        });
    }
};
